import axios from 'axios';
import themeMP4 from '../assets/mantle/mantlename service_bkg.mp4';


export function getURL(){
 return new Promise(function(resolve, reject){
    axios({
        method:'get',
        url:themeMP4,
        responseType: 'blob',
        type: 'video/mp4'
      }).then(res=>{
        let blob = new Blob([res.data]);
        let url = window.URL.createObjectURL(blob);
        resolve(url);
      }).catch(error=>{
        reject(error);
      })
 })
}
