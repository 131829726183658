
import styled from "styled-components";
import lock from './assets/op/lock.png'
import firstPage from './assets/mantle/firstPage2.png';
import { useEffect,useState} from "react";
import {getURL} from './utils/getURL';

const Container=styled.div`
width:100% ;
position:relative ;
height:100vh;
padding-top:80px ;
background-color:#000 ;
/* height:calc(100vh - 80px) ; */
/* margin-top:80px ; */

 `

const Inner=styled.div`
width:1280px ;
margin:0 auto ;
position:relative ;


@media screen and (max-width:1280px){
   width:100% ;
}

@media screen and (max-width:950px){
   main{
    width:100% ;
    display:flex ;
    justify-content:center ;
   }
}

 `

 const Inner2=styled(Inner)`
 position:absolute ;
 top:0 ;
 width:100%;
 height:100%;
 z-index:200;

 @media screen and (max-width:1500px){
    left:80px ;
  }

  @media screen and (max-width:1280px){
   left:0 ;
  }
 `
const Desc=styled.div`
  width:570px ;
  padding-top:137px ;
  text-align:left ;
  font-size:50px ;
  font-family: 'GT Walsheim Trial-Bd';
  color: #fff;
  line-height: 75px;
  z-index:400;

  i{
    font-style:normal ;
  }

  aside{
    padding-right:76px ;
  }

  @media screen and (max-width:1500px){
    width:495px ;
    font-size:46px ;
    line-height: 54px;
  }

  @media screen and (max-width:1280px){
   padding-left:117px ;
   box-sizing:content-box ;
  }


 @media screen and (max-width:1180px){
    width:435px ;
    font-size:40px ;
    line-height: 54px;

    aside{
    padding-right:26px ;
  }
 }

 @media screen and (max-width:950px){
    box-sizing:border-box ;
    padding-left:0px ;
 }

 @media screen and (max-width:750px){
  width:290px;
  font-size:30px ;
  padding-top:197px ;
  }

`

// const PurpleText=styled.div`
// color:#60DFFF;
// font-family: 'Galyon-Book';
// text-align:left ;
// white-space: pre-wrap;
// `

const Data=styled.div`
position:absolute ;
bottom:62px ;
margin-top:203px;
text-align:left ;
z-index:9999 ;

@media screen and (max-width:750px){
    bottom:39px ;
}
@media screen and (max-width:450px){
    bottom:100px ;
}
/* padding-top:25% ; */
 `

const Footer=styled.div`
/* display:inline-flex ; */
width:100% ;
margin-top: 14px;
font-size: 17px;
font-family: 'GT Walsheim Trial-Rg';
font-weight: normal;
color: #FFFFFF;
line-height: 60px;
display:flex ;
align-items:center ;
padding:23px 5vw 0 5vw;
/* white-space:pre-wrap ; */
white-space:nowrap;
word-spacing:5px;

&>img{
  width:30px ;
}

&>mark::before{
  content: "&";
  font-family: 'GT Walsheim Trial-Rg';
  margin:0 10px ;
}

@media screen and (max-width:750px){
    font-size:14px ;
    line-height:13px ;

    img:first-child{
      width:25px ;
    }
    img:last-child{
      height:14px !important;
      margin-bottom:3px !important ;
    }
}

 `
 const Cover=styled.div`
 position:fixed;
  top:0;
  right:0;
  z-index:9999;

  width:100%;
  height:100vh;
 `
const Vidio=styled.div` 
position:absolute ;
top:0;
right:0;
z-index:20;

&>video{
  user-select: none;
  pointer-events: none;
}



@media screen and (max-width:950px){
   transform:translateX(-17%) ;
}
@media screen and (max-width:361px){
   transform:translateX(-17%) ;
}
`


// Naming Service 
// on Mantle For Wallets, 
// Websites, and more
export function Linea(props){
  const {onEnterNextPage}=props;
  const [videodata,setVideoData]=useState();

  useEffect(()=>{
    const videos =document.getElementsByTagName("video");
    videos[0].play();

    getURL().then(url=>{
      setVideoData(url);
    }).catch(err=>{
      console.log(err);
    });
  },[])
    return (
        <Container>
           
            <Inner>
              <Inner2>
                <main>
                    <Desc>
                        Naming Service <br />
                        <section style={{display:"flex",alignItems:'center'}}>
                                {/* <mark style={{color:"#60DFFF",background:"transparent",whiteSpace:"pre-wrap"}}>naming for </mark>
                                wallets */}
                                on Mantle For Wallets,
                        </section>
                        Websites, and more
                        {/* <aside style={{width:"100%",textAlign:"right",}}>websites</aside>
                        <aside style={{width:"100%",textAlign:"right",color:"#737373"}}>on Linea</aside> */}
                    </Desc>
                </main>
                <Cover>

              </Cover>
              </Inner2>
                 {/* poster 属性规定视频下载时显示的图像，或者在用户点击播放按钮前显示的图像。 */}
                  <Vidio> 
                      <video
                      width="100%"  
                      autoPlay loop muted
                      playsInline
                      src={videodata}
                      x5-video-player-type="h5"
                      poster={firstPage}
                      >
                      </video>
                    </Vidio>
            </Inner>
            <Data>
              {/* <LogoCard /> */}
              <Footer onClick={(e)=>onEnterNextPage(e)}>
                {/* Omni-chain Solution Powered  */}
                Powered  by  
                <img src={lock} alt="" />
                LayerZero
                {/* <mark style={{color:"#60DFFF",background:"transparent",whiteSpace:"pre-wrap"}}></mark>
                <img style={{height:'16px',width:'auto',marginBottom:'5px'}} src={linea} alt="" /> */}
              </Footer>
          </Data>
        </Container>
    )
}




